// @flow
import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/header';
import Page from '../components/Page';

export default () => {
  return (
    <Page title="Our Privacy Policy">
      <Header
        thin
        title="Meet our team"
        subtitle="Dealing with a spine problem can be anxiety inducing. Our commitment is to help you through your care and recovery, treating you as a person, not just a patient."
        cta="Make an appointment"
      />
      <div style={{ maxWidth: 1040, margin: 'auto' }}>
        <br />
        <br />
        <p>
          <strong>Privacy Statement</strong>
        </p>
        <p>
          Spine Institute of Idaho is committed to protecting your privacy and
          developing technology that gives you the most powerful and safe online
          experience. This Statement of Privacy applies to the Spine Institute
          of Idaho Web site and governs data collection and usage. By using the
          Spine Institute of Idaho website, you consent to the data practices
          described in this statement.
        </p>
        <p>
          <strong>Collection of your Personal Information</strong>
        </p>
        <p>
          Spine Institute of Idaho collects personally identifiable information,
          such as your e-mail address, name, home or work address or telephone
          number. Spine Institute of Idaho also collects anonymous demographic
          information, which is not unique to you, such as your ZIP code, age,
          gender, preferences, interests and favorites.
        </p>
        <p>
          There is also information about your computer hardware and software
          that is automatically collected by Spine Institute of Idaho. This
          information can include: your IP address, browser type, domain names,
          access times and referring Web site addresses. This information is
          used by Spine Institute of Idaho for the operation of the service, to
          maintain quality of the service, and to provide general statistics
          regarding use of the Spine Institute of Idaho Web site.
        </p>
        <p>
          Please keep in mind that if you directly disclose personally
          identifiable information or personally sensitive data through Spine
          Institute of Idaho public message boards, this information may be
          collected and used by others. Note: Spine Institute of Idaho does not
          read any of your private online communications.
        </p>
        <p>
          Spine Institute of Idaho encourages you to review the privacy
          statements of Web sites you choose to link to from Spine Institute of
          Idaho so that you can understand how those Web sites collect, use and
          share your information. Spine Institute of Idaho is not responsible
          for the privacy statements or other content on Web sites outside of
          the Spine Institute of Idaho and Spine Institute of Idaho family of
          Web sites.
        </p>
        <p>
          <strong>Use of your Personal Information</strong>
        </p>
        <p>
          Spine Institute of Idaho collects and uses your personal information
          to operate the Spine Institute of Idaho Web site and deliver the
          services you have requested. Spine Institute of Idaho also uses your
          personally identifiable information to inform you of other products or
          services available from Spine Institute of Idaho and its affiliates.
          Spine Institute of Idaho may also contact you via surveys to conduct
          research about your opinion of current services or of potential new
          services that may be offered.
        </p>
        <p>
          Spine Institute of Idaho does not sell, rent or lease its customer
          lists to third parties. Spine Institute of Idaho may, from time to
          time, contact you on behalf of external business partners about a
          particular offering that may be of interest to you. In those cases,
          your unique personally identifiable information (e-mail, name,
          address, telephone number) is not transferred to the third party. In
          addition, Spine Institute of Idaho may share data with trusted
          partners to help us perform statistical analysis, send you email or
          postal mail, provide customer support, or arrange for deliveries. All
          such third parties are prohibited from using your personal information
          except to provide these services to Spine Institute of Idaho, and they
          are required to maintain the confidentiality of your information.
        </p>
        <p>
          Spine Institute of Idaho does not use or disclose sensitive personal
          information, such as race, religion, or political affiliations,
          without your explicit consent.
        </p>
        <p>
          Spine Institute of Idaho keeps track of the Web sites and pages our
          customers visit within Spine Institute of Idaho, in order to determine
          what Spine Institute of Idaho services are the most popular. This data
          is used to deliver customized content and advertising within Spine
          Institute of Idaho to customers whose behavior indicates that they are
          interested in a particular subject area.
        </p>
        <p>
          Spine Institute of Idaho Web sites will disclose your personal
          information, without notice, only if required to do so by law or in
          the good faith belief that such action is necessary to: (a) conform to
          the edicts of the law or comply with legal process served on Spine
          Institute of Idaho or the site; (b) protect and defend the rights or
          property of Spine Institute of Idaho; and, © act under exigent
          circumstances to protect the personal safety of users of Spine
          Institute of Idaho, or the public.
        </p>
        <p>
          <strong>Use of Cookies</strong>
        </p>
        <p>
          The Spine Institute of Idaho Web site use “cookies” to help you
          personalize your online experience. A cookie is a text file that is
          placed on your hard disk by a Web page server. Cookies cannot be used
          to run programs or deliver viruses to your computer. Cookies are
          uniquely assigned to you, and can only be read by a web server in the
          domain that issued the cookie to you.
        </p>
        <p>
          One of the primary purposes of cookies is to provide a convenience
          feature to save you time. The purpose of a cookie is to tell the Web
          server that you have returned to a specific page. For example, if you
          personalize Spine Institute of Idaho pages, or register with Spine
          Institute of Idaho site or services, a cookie helps Spine Institute of
          Idaho to recall your specific information on subsequent visits. This
          simplifies the process of recording your personal information, such as
          billing addresses, shipping addresses, and so on. When you return to
          the same Spine Institute of Idaho Web site, the information you
          previously provided can be retrieved, so you can easily use the Spine
          Institute of Idaho features that you customized.
        </p>
        <p>
          You have the ability to accept or decline cookies. Most Web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. If you choose to decline
          cookies, you may not be able to fully experience the interactive
          features of the Spine Institute of Idaho services or Web sites you
          visit.
        </p>
        <p>
          <strong>Security of your Personal Information</strong>
        </p>
        <p>
          Spine Institute of Idaho secures your personal information from
          unauthorized access, use or disclosure. Spine Institute of Idaho
          secures the personally identifiable information you provide on
          computer servers in a controlled, secure environment, protected from
          unauthorized access, use or disclosure. When personal information
          (such as a credit card number) is transmitted to other Web sites, it
          is protected through the use of encryption, such as the Secure Socket
          Layer (SSL) protocol.
        </p>
        <p>
          <strong>Changes to this Statement</strong>
        </p>
        <p>
          Spine Institute of Idaho will occasionally update this Statement of
          Privacy to reflect company and customer feedback. Spine Institute of
          Idaho encourages you to periodically review this Statement to be
          informed of how Spine Institute of Idaho is protecting your
          information.
        </p>
        <p>
          <strong>Contact Information</strong>
        </p>
        <p>
          Spine Institute of Idaho welcomes your comments regarding this
          Statement of Privacy. If you believe that Spine Institute of Idaho has
          not adhered to this Statement, please contact Spine Institute of Idaho
          at <a href="mailto:info@spineidaho.com">info@spineidaho.com</a>. We
          will use commercially reasonable efforts to promptly determine and
          remedy the problem.
        </p>
      </div>
      <Footer />
    </Page>
  );
};
